import Axios from "axios";
import { Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { apiBaseUrl } from "./AppSettings";
import Centre from "./models/Centre";
import CustomField from "./models/CustomField";
import CustomFieldValue from "./models/CustomFieldValue";
import Event from "./models/Event";
import { updateSevaDetailsForEvent } from "./store/actions/DonationDetailsActions";
import DonorDetails from "./store/actions/DonorDetails";
import { updateDonorDetails } from "./store/actions/DonorDetailsActions";
import { setEventGroupName } from "./store/actions/UIStateActions";

interface GujaratiClassRegistrationFormProps {
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    birthYear: number;
    birthMonth: string;
    levelOfEnrolment: number;
    fatherName: string;
    motherName: string;
    center: number;
    email: string;
    mobileNumber: string;
    streetAddress: string;
    suburb: string;
    zip: number;
}

const initialValues: GujaratiClassRegistrationFormProps = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    levelOfEnrolment: 0,
    fatherName: "",
    motherName: "",
    center: 0,
    birthYear: 0,
    birthMonth: "",
    email: "",
    mobileNumber: "",
    streetAddress: "",
    suburb: "",
    zip: 0
}

interface StateProps {
    donor: any;
    uiState: any;
}

interface DispatchProps {
    updateDonorDetails: (donorDetails: DonorDetails) => void;
    updateEventGroupName: (eventGroupName: string) => void;
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event) => void;
}

type ComponentProps = StateProps & DispatchProps;

export function GujaratiClassRegistration(props: ComponentProps) {

    const [events, setEvents] = React.useState<Array<Event>>([]);
    const [allDetailsEntered, setAllDetailsEntered] = React.useState<boolean>(false);

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        middleName: Yup.string().required().max(50),
        levelOfEnrolment: Yup.number().required().min(1, "Level of Enrolment is required"),
        center: Yup.number().required().min(1, "Center is required"),
        birthYear: Yup.number().required("Birth Year is required").min(1999, "Birth Year is required"),
        birthMonth: Yup.string().required("Birth Month is required"),
        gender: Yup.string().required("Gender is required"),
        fatherName: Yup.string().required().max(250),
        motherName: Yup.string().required().max(250),
        mobileNumber: Yup.string().matches(/04([0-9]){8}\b/gm, "Please enter mobile number in 04xxxxxxxx").required(),
        email: Yup.string().email().required(),
        streetAddress: Yup.string().required().max(255),
        suburb: Yup.string().required().max(255),
        zip: Yup.number().required().min(1, "Zipcode of suburb is required")
    });

    useEffect(() => {
        props.updateEventGroupName("gujarati-class-registration-2025");
        loadEvents();
    }, []);

    const loadEvents = async () => {
        const response = await Axios.get<Array<Event>>(`${apiBaseUrl}/api/events?eventGroupName=gujarati-class-registration-2025`);
        const events = response.data;
        setEvents(events);
    }

    const onSubmit = (values: GujaratiClassRegistrationFormProps) => {
        props.updateDonorDetails({
            firstName: `${values.firstName} ${values.middleName}`,
            centre: values.center,
            email: values.email,
            lastName: values.lastName,
            mobile: values.mobileNumber,
        });

        const selectedEvent = events.find(evt => evt.id === parseInt(values.levelOfEnrolment.toString())) as Event;

        const mappedCustomFields = mapCustomFields(selectedEvent, values);

        props.updateSevaDetailsForEvent(1,
            mappedCustomFields,
            selectedEvent);

        setAllDetailsEntered(true);
    }

    const mapCustomFields = (
        selectedEvent: Event,
        values: GujaratiClassRegistrationFormProps) => {

        const mappedCustomFields = new Array<CustomFieldValue>();

        const fatherNameCustomField = GetCustomField("Father Name", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: fatherNameCustomField.id,
                customFieldValue: values.fatherName
            });

        const motherNameCustomField = GetCustomField("Mother Name", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: motherNameCustomField.id,
                customFieldValue: values.motherName
            });

        const addressCustomField = GetCustomField("Address", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: addressCustomField.id,
                customFieldValue: values.streetAddress
            });

        const postCodeCustomField = GetCustomField("PostCode", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: postCodeCustomField.id,
                customFieldValue: values.zip.toString()
            });

        const suburbCustomField = GetCustomField("Suburb", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: suburbCustomField.id,
                customFieldValue: values.suburb.toString()
            });

        const birthYearCustomFieldId = GetCustomField("Birth Year", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: birthYearCustomFieldId.id,
                customFieldValue: values.birthYear.toString()
            });

        const birthMonthCustomField = GetCustomField("Birth Month", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: birthMonthCustomField.id,
                customFieldValue: values.birthMonth.toString()
            });

        const genderCustomField = GetCustomField("Gender", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: genderCustomField.id,
                customFieldValue: values.gender
            });

        return mappedCustomFields;
    }

    const GetCustomField = (name: string, event: Event) => {
        return event
            .customFields
            .find(cf => cf.label === name) as CustomField;
    }

    const renderCentreSelect = (): Array<JSX.Element> => {
        const optionsForCentres = new Array<JSX.Element>();
        optionsForCentres.push(<option key={`Centre-${Centre.Albury}`} value={Centre.Albury}>Albury</option>);    
        optionsForCentres.push(<option key={`Centre-${Centre.Darwin}`} value={Centre.Darwin}>Darwin</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Brisbane}`} value={Centre.Brisbane}>Brisbane Mandir</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.BrisbaneNorth}`} value={Centre.BrisbaneNorth}>Brisbane North</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Dubbo}`} value={Centre.Dubbo}>Dubbo</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Geelong}`} value={Centre.Geelong}>Geelong</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.GoldCoast}`} value={Centre.GoldCoast}>GoldCoast</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Gosford}`} value={Centre.Gosford}>Gosford</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Hobart}`} value={Centre.Hobart}>Hobart</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Sydney}`} value={Centre.Sydney}>Sydney</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.SunshineCoast}`} value={Centre.SunshineCoast}>Sunshine Coast</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Tamworth}`} value={Centre.Tamworth}>Tamworth</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Canberra}`} value={Centre.Canberra}>Canberra</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Mittagong}`} value={Centre.Mittagong}>Mittagong</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.MelbourneEast}`} value={Centre.MelbourneEast}>Melbourne East</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.MelbourneMandir}`} value={Centre.MelbourneMandir}>Melbourne Mandir</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.MelbourneSouth}`} value={Centre.MelbourneSouth}>Melbourne South</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.MelbourneWest}`} value={Centre.MelbourneWest}>Melbourne West</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.Adelaide}`} value={Centre.Adelaide}>Adelaide</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.PerthMandir}`} value={Centre.PerthMandir}>Perth Mandir</option>);
        optionsForCentres.push(<option key={`Centre-${Centre.PerthSouth}`} value={Centre.PerthSouth}>Perth South</option>);

        return optionsForCentres;
    }

    const renderBirthYears = () => {
        const birthYears: JSX.Element[] = [];

        for (var ctr = 2019; ctr >= 2000; ctr--) {
            birthYears.push((<option>{ctr}</option>))
        }

        return birthYears;
    }

    const renderBirthMonths = (formikProps: FormikProps<GujaratiClassRegistrationFormProps>) => {

        const birthYear = formikProps.values.birthYear;
        const canBeBornAfterJulyForYear = parseInt(birthYear?.toString()) !== 2019;
        const birthMonths: JSX.Element[] = [];

        birthMonths.push((<option>January</option>));
        birthMonths.push((<option>February</option>));
        birthMonths.push((<option>March</option>));
        birthMonths.push((<option>April</option>));
        birthMonths.push((<option>May</option>));
        birthMonths.push((<option>June</option>));
        birthMonths.push((<option>July</option>));

        if (canBeBornAfterJulyForYear === true) {
            birthMonths.push((<option>August</option>));
            birthMonths.push((<option>September</option>));
            birthMonths.push((<option>October</option>));
            birthMonths.push((<option>November</option>));
            birthMonths.push((<option>December</option>));
        }
        return birthMonths;
    }

    const resetBirthMonths = (formikProps: FormikProps<GujaratiClassRegistrationFormProps>) => { 
        formikProps.setFieldValue("birthMonth", "-", false);
    }

    const resetLevelOfEnrolment = (formikProps: FormikProps<GujaratiClassRegistrationFormProps>) => { 
        formikProps.setFieldValue("levelOfEnrolment", 0, false);
    }

    const renderLevelsOfEnrolment = (formikProps: FormikProps<GujaratiClassRegistrationFormProps>) => {
        const selectedCenter = formikProps.values.center;
        let filteredEvents = null;
        /*
        if(selectedCenter && [Centre.Perth, Centre.PerthMandir].indexOf(parseInt(selectedCenter.toString())) !== -1)
        {
            const levelsForPerth = ["Level - 1","Level - 2", "Level - 3", "Level - 6"];
            filteredEvents = events.filter(evt => levelsForPerth.indexOf(evt.name) !== -1);
        }
        else*/
            filteredEvents = events;

        return filteredEvents.map(evt => (<option key={`event-${evt.id}`} value={evt.id}>{evt.name}</option>));
    }

    const renderForm = () => (<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
    >
        {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                <Row>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>Students First Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter first name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.firstName}
                                name="firstName" />
                            <Form.Text className="text-muted">
                                Please enter students first name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.firstName &&
                                formikProps.errors.firstName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formMiddleName">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter middle name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.middleName}
                                name="middleName" />
                            <Form.Text className="text-muted">
                                Please enter students middle name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.middleName &&
                                formikProps.errors.middleName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.middleName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter last name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.lastName}
                                name="lastName" />
                            <Form.Text className="text-muted">
                                Please enter students last name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.lastName &&
                                formikProps.errors.lastName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={4} sm={12}>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select"
                            value={formikProps.values.gender}
                            onChange={formikProps.handleChange}
                            name="gender">
                            <option value="">-</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Please select gender of the student.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.gender &&
                            formikProps.errors.gender ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.gender}</div>) : (<React.Fragment />)}
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Label>Birth Year</Form.Label>
                        <Form.Control as="select"
                            value={formikProps.values.birthYear}
                            onChange={e => {
                                formikProps.handleChange(e);
                                resetBirthMonths(formikProps);
                            }}
                            name="birthYear">
                            <option value="">-</option>
                            {renderBirthYears()}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Please select year of birth.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.birthYear &&
                            formikProps.errors.birthYear ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.birthYear}</div>) : (<React.Fragment />)}
                    </Col>

                    <Col md={4} sm={12}>
                        <Form.Label>Birth Month</Form.Label>
                        <Form.Control as="select"
                            value={formikProps.values.birthMonth}
                            onChange={formikProps.handleChange}
                            name="birthMonth">
                            <option value="">-</option>
                            {renderBirthMonths(formikProps)}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Please select month of birth.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.birthMonth &&
                            formikProps.errors.birthMonth ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.birthMonth}</div>) : (<React.Fragment />)}
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Label>Select the Center</Form.Label>
                        <Form.Group controlId="formCenter">
                            <Form.Control as="select"
                                name="center"
                                value={formikProps.values.center}
                                onChange={e => {
                                    formikProps.handleChange(e);
                                    resetLevelOfEnrolment(formikProps);
                                }} >
                                <option>-</option>
                                {renderCentreSelect()}
                            </Form.Control>
                            <Form.Text className="text-muted">
                                Please select the centre.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.center &&
                                formikProps.errors.center ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.center}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Label>Level Of Enrolment</Form.Label>
                        <Form.Control as="select"
                            value={formikProps.values.levelOfEnrolment}
                            onChange={formikProps.handleChange}
                            name="levelOfEnrolment">
                            <option value={0}>-</option>
                            {renderLevelsOfEnrolment(formikProps)}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Please select level of enrolment.
                        </Form.Text>
                        {formikProps.touched &&
                            formikProps.touched.levelOfEnrolment &&
                            formikProps.errors.levelOfEnrolment ?
                            (<div className="fade alert alert-danger show">{formikProps.errors.levelOfEnrolment}</div>) : (<React.Fragment />)}

                    </Col>
                    
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formFathersName">
                            <Form.Label>Father's Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Father's name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.fatherName}
                                name="fatherName" />
                            <Form.Text className="text-muted">
                                Please enter students father name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.fatherName &&
                                formikProps.errors.fatherName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.fatherName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formMotherName">
                            <Form.Label>Mother's Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Mother's name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.motherName}
                                name="motherName" />
                            <Form.Text className="text-muted">
                                Please enter students mother name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.middleName &&
                                formikProps.errors.middleName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.motherName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your email id
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.email &&
                                formikProps.errors.email ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile number</Form.Label>
                            <Form.Control type="text"
                                placeholder="04XXXXXXXX"
                                pattern="0[0-9]{9}"
                                name="mobileNumber"
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your mobile number starting with 04.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.mobileNumber &&
                                formikProps.errors.mobileNumber ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Address"
                                name="streetAddress"
                                value={formikProps.values.streetAddress}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your address
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.streetAddress &&
                                formikProps.errors.streetAddress ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.streetAddress}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formSuburb">
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Suburb"
                                name="suburb"
                                value={formikProps.values.suburb}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your suburb
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.suburb &&
                                formikProps.errors.suburb ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.suburb}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formZip">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter ZipCode"
                                name="zip"
                                value={formikProps.values.zip}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter ZipCode
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.zip &&
                                formikProps.errors.zip ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.zip}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit" size="lg">
                    NEXT
                </Button>
            </Form>
        )}

    </Formik>);

    const renderRedirect = () => (<Redirect to="/confirm" />);

    return (<div>
        {allDetailsEntered ? renderRedirect() : renderForm()}
    </div>)
}

const mapStateToProps = (state: any): StateProps => {
    return {
        donor: state.donor,
        uiState: state.uiState
    };
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        updateDonorDetails: (donorDetails: DonorDetails) => dispatch(updateDonorDetails(donorDetails)),
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
        updateSevaDetailsForEvent: (
            sevaCount: number,
            customFieldValues: CustomFieldValue[],
            event: Event) =>
            dispatch(updateSevaDetailsForEvent(sevaCount, customFieldValues, event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GujaratiClassRegistration);