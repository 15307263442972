import Centre from "./models/Centre";

const tShirtCenters = [
    Centre.Adelaide,
    Centre.Albury,
    Centre.AliceSprings,
    Centre.Auckland,
    Centre.Ballarat,
    Centre.Berri,
    Centre.Brisbane,
    Centre.BrisbaneNorth,
    Centre.BrokenHill,
    Centre.Canberra,
    Centre.Carins,
    Centre.Christchurch,
    Centre.Darwin,
    Centre.Dubbo,
    Centre.Gatton,
    Centre.Geelong,
    Centre.GoldCoast,
    Centre.Griffith,
    Centre.Hamilton,
    Centre.Hobart,
    Centre.Launceston,
    Centre.MelbourneEast,
    Centre.MelbourneMandir,
    Centre.MelbourneSouth,
    Centre.MelbourneWest,
    Centre.Mildura,
    Centre.Newcastle,
    Centre.Orange,
    Centre.PerthMandir,
    Centre.PerthSouth,
    Centre.Rotorua,
    Centre.Shepparton,
    Centre.SunshineCoast,
    Centre.Sydney,
    Centre.Toowoomba,
    Centre.Townsville,
    Centre.WaggaWagga,
    Centre.Wellington
];

const tShirtsWithFijiCenters = [...tShirtCenters, Centre.Ba, Centre.Nadi, Centre.Suva];

export default function getCentersForEventGroup(eventGroupName: string): Centre[] {
    const loweredEventGroupName = eventGroupName?.toLocaleLowerCase();
    switch (loweredEventGroupName) {
        case "anz-kishor-kishori-shibir":
            return [
                Centre.Sydney,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Adelaide,
                Centre.Perth,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Darwin
            ];
        case "bal-balika-exam-2024":
            return [
                Centre.Adelaide,
                Centre.Albury,
                Centre.Balwyn,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Cranbourne,
                Centre.Darwin,
                Centre.Geelong,
                Centre.GoldCoast,
                Centre.Hobart,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Perth,
                Centre.Shepparton,
                Centre.Sydney,
            ];
        case "annakut-2024":
        case "annakut-2023":
            return [
                Centre.Adelaide,
                Centre.Albury,
                Centre.Ballarat,
                Centre.Bendigo,
                Centre.Brisbane,
                Centre.BrisbaneNorth,
                Centre.Canberra,
                Centre.Darwin,
                Centre.Geelong,
                Centre.GoldCoast,
                Centre.Griffith,
                Centre.Hobart,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.MelbourneEast,
                Centre.Newcastle,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Shepparton,
                Centre.Sydney,
                Centre.Toowoomba
            ];
        case "gold-coast-mandir-mahotsav-t-shirts":
            return [
                Centre.GoldCoast
            ];
        case "sunshine-coast-2025":
            return [
                Centre.SunshineCoast
            ];
        case "sydney-mandir-murti-pratishtha-t-shirts":
        case "sydney-madir-shilanyas-mahotsav-t-shirts":
            return [
                Centre.Sydney
            ];
        case "1000-nakkar-vidwan-mahotsav-t-shirts":
        case "nilkanth-varni-murti-pratishtha-t-shirts":
        case "akshardham-bhumi-pujan-t-shirts":
        case "fuldol-2025-t-shirts":
        case "bhavya-yoddha-t-shirts":
        case "audio-visual-team-t-shirts":
            return tShirtsWithFijiCenters;
        case "msm-vicharan-2025":
            return [
                Centre.International,
                Centre.Adelaide,
                Centre.Albury,
                Centre.Auckland,
                Centre.Balwyn,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Christchurch,
                Centre.Darwin,
                Centre.Dubbo,
                Centre.Gatton,
                Centre.Geelong,
                Centre.GoldCoast,
                Centre.Gosford,
                Centre.Griffith,
                Centre.Hobart,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Mittagong,
                Centre.Newcastle,
                Centre.Perth,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Shepparton,
                Centre.Sydney,
                Centre.Tamworth,
                Centre.Toowoomba,
                Centre.Wellington
            ];
    };

    return [
        Centre.Adelaide,
        Centre.Albury,
        Centre.Auckland,
        Centre.Balwyn,
        Centre.Brisbane,
        Centre.Canberra,
        Centre.Christchurch,
        Centre.Darwin,
        Centre.Dubbo,
        Centre.Gatton,
        Centre.Geelong,
        Centre.GoldCoast,
        Centre.Gosford,
        Centre.Griffith,
        Centre.Hobart,
        Centre.International,
        Centre.MelbourneMandir,
        Centre.MelbourneSouth,
        Centre.MelbourneWest,
        Centre.Mittagong,
        Centre.Newcastle,
        Centre.Perth,
        Centre.PerthMandir,
        Centre.PerthSouth,
        Centre.Shepparton,
        Centre.Sydney,
        Centre.Tamworth,
        Centre.Toowoomba,
        Centre.Wellington
    ];
}
