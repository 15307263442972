import React, { useState } from "react";
import { Card, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import Event from "../models/Event";
import CustomFieldValue from "../models/CustomFieldValue";
import "./msmVicharanStyles.css";
import Select from "react-select";

export interface MsmVicharanEventRendererProps {
    events: Event[],
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
}

export default function MsmVicharanEventRenderer(props: MsmVicharanEventRendererProps) {

    const [selectedSeva, setSelectedSeva] = useState<any>({
        "Sydney-Yajman-Seva": "None",
        "Melbourne-Yajman-Seva": "None",
        "Mahotsav-Sabha-Sponsorship": "None",
        "Utsav-Sabha-Sponsorship": "None",
        "Other-Seva": "None",
        "Sydney-Shikharbaddh": "None",
        "Guruhari-Suvarna-Tula": "None",
        "Nilkanth-Varni-Seva": "None"
    });


    const onSevaSelected = (sevaId: string, checked: boolean) => {
        const matchingEventIndex = props.events.findIndex(e => e.name === sevaId);

        if (matchingEventIndex === -1) {
            alert("Invalid Seva Selected");
            return;
        }

        props.updateSevaDetailsForEvent(checked === true ? 1 : 0, [], props.events[matchingEventIndex]);
    }

    const contactSevas = [
        "Shilanyash-Vidhi-Diamond", 
        "Shilanyash-Vidhi-Platinum", 
        "Shilanyash-Vidhi-Gold", 
        "Shilanyash-Vidhi-Silver"];

    const OnSelectedSevaChanged = (groupName: string, selectedSevaId: string) => {

        if (contactSevas.indexOf(selectedSevaId) !== -1) {
            alert("Please contact +61 430 272 660 to get more details of the seva");
            return;
        }

        // Unselect existing selection if any
        const copyOfSelectedSeva = { ...selectedSeva };

        if (copyOfSelectedSeva[groupName] !== "None")
            onSevaSelected(copyOfSelectedSeva[groupName], false);

        // Save new selection
        copyOfSelectedSeva[groupName] = selectedSevaId;
        if (copyOfSelectedSeva[groupName] !== "None")
            onSevaSelected(copyOfSelectedSeva[groupName], true);

        setSelectedSeva(copyOfSelectedSeva);
    }

    const renderSevaLabel = (label: string) => (<span>&nbsp;{label}</span>);

    const customStyles = {
        option: (provided: any, state: any) => {
            return {
                ...provided,
                borderBottom: '1px dotted pink',
                color: "#8E8D8D",
                backgroundColor: "none",
                fontWeight: "bold"
            };
        },
    };

    const renderYajmanSeva = () => {

        const selectOptions = [
            { Name: "Diamond (Call +61 430 272 660 for seva details)", Id: "Shilanyash-Vidhi-Diamond" },
            { Name: "Platinum (Call +61 430 272 660 for seva details)", Id: "Shilanyash-Vidhi-Platinum" },
            { Name: "Gold (Call +61 430 272 660 for seva details)", Id: "Shilanyash-Vidhi-Gold" },
            { Name: "Silver (Call +61 430 272 660 for seva details)", Id: "Shilanyash-Vidhi-Silver" },
            { Name: "Bronze (A$ 15,000)", Id: "Sydney-Shikharbaddh-Shilanyash-Vidhi-Bronze" },
        ].map(function (item) {
            return { value: item.Id, label: item.Name };
        });

        const bhumiPujanSeva = [
            { Name: "Diamond (A$ 151,000)", Id: "Akshardham-Bhumi-Pujan-Diamond" },
            { Name: "Platinum (A$ 101,000)", Id: "Akshardham-Bhumi-Pujan-Platinium" },
            { Name: "Gold (A$ 75,000)", Id: "Akshardham-Bhumi-Pujan-Gold" },
            { Name: "Silver  (A$ 31,000)", Id: "Akshardham-Bhumi-Pujan-Silver" },
            { Name: "Bronze (A$ 15,000)", Id: "Akshardham-Bhumi-Pujan-Bronze" },
        ].map(function (item) {
            return { value: item.Id, label: item.Name };
        });

        const bhaktiTulaSeva = [
            { Name: "Diamond (A$ 100,000)", Id: "Bhakti-Tula-Diamond" },
            { Name: "Platinum (A$ 50,000)", Id: "Bhakti-Tula-Platinium" },
            { Name: "Gold (A$ 25,000)", Id: "Bhakti-Tula-Gold" },
            { Name: "Silver  (A$ 5,100)", Id: "Bhakti-Tula-Silver" },
            { Name: "Bronze (A$ 1,100)", Id: "Bhakti-Tula-Bronze" },
        ].map(function (item) {
            return { value: item.Id, label: item.Name };
        });

        return (
            <Row>
                <Col sm="12" md="4">
                    <Container>
                        <Card className="card">
                            <Card.Img variant="top" src="msm-vicharan/shilanyas.jpg" />
                            <Card.Body>
                                <Card.Title className="longEventName">Shikharbaddha Mandir Shilanyas Vidhi @ Sydney</Card.Title>
                                {/*//@ts-ignore*/}
                                <Select options={selectOptions} styles={customStyles} onChange={(evt) => OnSelectedSevaChanged('Sydney-Yajman-Seva', evt.value)}></Select>
                            </Card.Body>
                        </Card>
                    </Container>
                </Col>

                <Col sm="12" md="4">
                    <Container>
                        <Card className="card">
                            <Card.Img variant="top" src="msm-vicharan/bhumi_pujan.jpg" />
                            <Card.Body>
                                <Card.Title className="longEventName">Akshardham Bhumi Pujan @ Melbourne</Card.Title>
                                {/*//@ts-ignore*/}
                                <Select options={bhumiPujanSeva}
                                    styles={customStyles}
                                    onChange={(evt: any) => OnSelectedSevaChanged('Melbourne-Yajman-Seva', evt.value)}>
                                </Select>
                            </Card.Body>
                        </Card>
                    </Container>
                </Col>

                <Col sm="12" md="4">
                    <Container>
                        <Card className="card">
                            <Card.Img variant="top" src="msm-vicharan/bhakti_tula.jpg" />
                            <Card.Body>
                                <Card.Title className="longEventName">Aksharbrahma Guruhari Suvarna Tula @ Melbourne</Card.Title>
                                {/*//@ts-ignore*/}
                                <Select options={bhaktiTulaSeva}
                                    styles={customStyles}
                                    onChange={(evt: any) => OnSelectedSevaChanged('Guruhari-Suvarna-Tula', evt.value)}>
                                </Select>
                            </Card.Body>
                        </Card>
                    </Container>
                </Col>

            </Row>);
    }

    const renderCard = (imagePath: string, eventName: string, eventLoc: string, eventId: string, sevaAmount: string, isEventNameLong: boolean) => {
        return (
            <Card className="card">
                <Card.Img variant="top" src={imagePath} />
                <Card.Body>
                    <Card.Title className={isEventNameLong ? "longEventName" : "eventName"}>{eventName} <br /> {eventLoc}</Card.Title>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                        <Form.Check type="checkbox"
                            onChange={(e: any) => onSevaSelected(e.target.value, e.target.checked)}
                            value={eventId}
                            name={eventId}
                            label={(<span>&nbsp;${sevaAmount}</span>)}>
                        </Form.Check>
                    </ListGroup.Item>
                </ListGroup>
            </Card>);
    }

    /*
        Mahotsav-Sabha-Sponsor-Akshardham-Bhoomi-Pujan
        Mahotsav-Sabha-Sponsor-Tapomurti-Shri-Nilkanth-Varni-Maharaj
        Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Murti-Pratistha
        Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Shilanyas
        Mahotsav-Sabha-Sponsor-Fuldol
        Mahotsav-Sabha-Sponsor-Maha-Annakut
        Mahotsav-Sabha-Sponsor-Shri-Swaminarayan-Mahayagna
    */
    const renderMahotsavSabhaSponsorship = () => {

        const mahotsavSevas =
            [
                { Name: "Akshardham Bhumi Pujan @ Melbourne (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Akshardham-Bhoomi-Pujan" },
                /*{ Name: "Tapomurti Shri Nilkanth Varni Pratishtha @ Sydney (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Tapomurti-Shri-Nilkanth-Varni-Maharaj" },*/
                { Name: "Sydney Shikharbaddha Mandir Murti Pratishtha (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Murti-Pratistha" },
                { Name: "Sydney Shikharbaddha Mandir Shilanyas Vidhi (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shikharbaddh-Mandir-Shilanyas" },
                { Name: "Fuldol (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Fuldol" },
                { Name: "Shri Swaminarayan Vishwashanti Mahayagna (A$ 10,000)", Id: "Mahotsav-Sabha-Sponsor-Shri-Swaminarayan-Mahayagna" },
            ].map(function (item) {
                return { value: item.Id, label: item.Name };
            });

        return (<Container>
            <Card className="card">
                <Card.Img variant="top" src="msm-vicharan\fuldol.jpg" />
                <Card.Body>
                    <Card.Title className="longEventName">Mahotsav Sabha Sponsorship <br /> &nbsp;</Card.Title>
                    {/*//@ts-ignore*/}
                    <Select options={mahotsavSevas}
                        styles={customStyles}
                        onChange={(evt: any) => OnSelectedSevaChanged('Mahotsav-Sabha-Sponsorship', evt.value)}>
                    </Select>
                </Card.Body>
            </Card>
        </Container>);
    }

    /*
        Utsav-Sabha-Sponsor-Swagat-Sabha-Sydney
Utsav-Sabha-Sponsor-Swagat-Sabha-Melbourne
Utsav-Sabha-Sponsor-Satsang-Diksha-Janmotsav
Utsav-Sabha-Sponsor-Diksha-Mahotsav
Utsav-Sabha-Sponsor-Guruhari-Bhakti-Tula
Utsav-Sabha-Sponsor-Nakkar-Vidvan-Grand-Falicitation
Utsav-Sabha-Sponsor-Nagar-Yatra

 1.Swagat Sabha 
 2.Satsang Diksha Janomtsav 
 3. Diksha Mahotsav 
 4. Guruhari Bhakti Tula 
 5.Nakkar Vidvan Grand Falicitation 
 6. Nagar Yatra 

    */
    const renderUtsavSabhaSponsorship = () => {
        const utsavSevas = [
            { Name: "Swagat Sabha @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Swagat-Sabha-Melbourne" },
            { Name: "Swagat Sabha @ Sydney (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Swagat-Sabha-Sydney" },
            { Name: "Satsang Diksha Janmotsav @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Satsang-Diksha-Janmotsav" },
            { Name: "Diksha Mahotsav @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Diksha-Mahotsav" },
            { Name: "Guruhari Bhakti Tula @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Guruhari-Bhakti-Tula" },
            { Name: "Nakkar Vidwan Grand Falicitation @ Melbourne (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Nakkar-Vidvan-Grand-Falicitation" },
            { Name: "Nagar Yatra @ Sydney (A$ 5000)", Id: "Utsav-Sabha-Sponsor-Nagar-Yatra" },]
            .map(function (item) {
                return { value: item.Id, label: item.Name };
            });

        return (
            <Container>
                <Card className="card">
                    <Card.Img variant="top" src="msm-vicharan\nagar_yatra.jpg" />
                    <Card.Body>
                        <Card.Title className="longEventName">Utsav Sabha Sponsorship <br /> &nbsp; </Card.Title>
                        {/*//@ts-ignore*/}
                        <Select options={utsavSevas}
                            styles={customStyles}
                            onChange={(evt: any) => OnSelectedSevaChanged('Utsav-Sabha-Sponsorship', evt.value)}>
                        </Select>
                    </Card.Body>
                </Card>
            </Container>);
    }

    const murtiPratishthaSevaItems = [
    /*{ "SevaId": "Sydney-Shikharbaddh-Tapomurti-Shri-Nilkanth-Varni-Maharaj", "SevaName": "Tapomurti Shri Nilkanth Varni Maharaj", "SevaAmount": "51,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Nilkanthvarni-Abhishek-Murti", "SevaName": "Shri Nilkanthvarni Abhishek Murti", "SevaAmount": "11,000" },*/
    { "SevaId": "Sydney-Shikharbaddh-Sinhasan-Seva", "SevaName": "Sinhasan Seva", "SevaAmount": "25,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Akshar-Purushottam-Maharaj-Murti-Seva", "SevaName": "Shri Akshar Purushottam Maharaj Murti Seva ", "SevaAmount": "21,000" },
    { "SevaId": "Sydney-Shikharbaddh-Shri-Ghanshayam-Maharaj-Murti-Seva", "SevaName": "Shri Ghanshyam Maharaj Murti Seva", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Ram-Parivar", "SevaName": "Bhagwan Shri Ram Parivar", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Shankar-Parivar", "SevaName": "Bhagwan Shri Shankar Parivar", "SevaAmount": "15,000" },
    { "SevaId": "Sydney-Shikharbaddh-Bhagvan-Shri-Krishna-and-Shri-Radhikaji", "SevaName": "Bhagwan Shri Krishna and Shri Radhikaji", "SevaAmount": "15,000" }];

    const renderSydneyShikharbadhMandirMurtiPratisthaSeva = () => {
        const murtiPratishthaSevas = murtiPratishthaSevaItems.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Card className="card">
                    <Card.Img variant="top" src="msm-vicharan\murti_pratishtha.jpg" />
                    <Card.Body>
                        <Card.Title className="longEventName">Sydney Shikharbaddha Mandir <br /> Murti Seva </Card.Title>
                         {/*//@ts-ignore*/}
                         <Select options={murtiPratishthaSevas}
                            styles={customStyles}
                            onChange={(evt: any) => OnSelectedSevaChanged('Sydney-Shikharbaddh', evt.value)}>
                        </Select>
                    </Card.Body>
                </Card>
            </Container>);
    }

    const miscSevaItems = [
        { "SevaId": "Other-Akshar-Purushottam-Maharaj-Thal", "SevaName": "Shri Akshar Purushottam Maharaj Thal / Day", "SevaAmount": "250" },
        { "SevaId": "Other-Param-Pujya-Mahant-Swami-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj", "SevaAmount": "500" },
        { "SevaId": "Other-Sant-Rasoi", "SevaName": "Sant Rasoi / Day", "SevaAmount": "751" },
        { "SevaId": "Other-Satsang-Sabha-Sponsor-Sydney", "SevaName": "Satsang Sabha Sponsor @ Sydney", "SevaAmount": "1,100" },
        { "SevaId": "Other-Satsang-Sabha-Sponsor-Melbourne", "SevaName": "Satsang Sabha Sponsor @ Melbourne", "SevaAmount": "1,100" },
        { "SevaId": "Other-Param-Pujya-Mahant-Swami-And-All-Santo-Dhotiya-Seva", "SevaName": "Dhotiya Seva - P.P. Mahant Swami Maharaj and all Vicharan Santo", "SevaAmount": "2,500" }];

    const renderOtherMiscSeva = () => {

        const otherSevas = miscSevaItems.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Card className="card">
                    <Card.Img variant="top" src="msm-vicharan\vishwa_shanti.jpg" />
                    <Card.Body>
                        <Card.Title className="longEventName">Other Donation Seva<br/>&nbsp;</Card.Title>
                         {/*//@ts-ignore*/}
                         <Select options={otherSevas}
                            styles={customStyles}
                            onChange={(evt: any) => OnSelectedSevaChanged('Other-Seva', evt.value)}>
                        </Select>
                    </Card.Body>
                </Card>
            </Container>);
    }

    const nilkanthVarniSeva = [
        { "SevaId": "Nilkanth-Varni-Seva-Diamond", "SevaName": "Diamond", "SevaAmount": "51,000" },
        { "SevaId": "Nilkanth-Varni-Seva-Platinum", "SevaName": "Platinum", "SevaAmount": "25,000" },
        { "SevaId": "Nilkanth-Varni-Seva-Gold", "SevaName": "Gold", "SevaAmount": "15,000" },
    ];

    const renderNilkanthVarniSeva = () => {

        const otherSevas = nilkanthVarniSeva.map(function (item) {
            return { value: item.SevaId, label: `${item.SevaName} - A$${item.SevaAmount}` };
        });

        return (
            <Container>
                <Card className="card">
                    <Card.Img variant="top" src="msm-vicharan\tapomurti_nilkanth.jpg" />
                    <Card.Body>
                        <Card.Title className="longEventName">Tapomurti Shri Nilkanth Varni Murti Seva </Card.Title>
                         {/*//@ts-ignore*/}
                         <Select options={otherSevas}
                            styles={customStyles}
                            onChange={(evt: any) => OnSelectedSevaChanged('Nilkanth-Varni-Seva', evt.value)}>
                        </Select>
                    </Card.Body>
                </Card>
            </Container>);
    }

    return (<>
        <div id="sevacardcontainers">
            {renderYajmanSeva()}
            <Row>
                <Col sm="12" md="4">
                    {renderMahotsavSabhaSponsorship()}
                </Col>
                <Col sm="12" md="4">
                    {renderUtsavSabhaSponsorship()}
                </Col>
                <Col sm="12" md="4">
                    {renderSydneyShikharbadhMandirMurtiPratisthaSeva()}
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="2"></Col>
                <Col sm="12" md="4">
                    {renderNilkanthVarniSeva()}
                </Col>
                <Col sm="12" md="4">
                    {renderOtherMiscSeva()}
                </Col>
                <Col sm="12" md="2"></Col>
            </Row>


        </div>
    </>);
}